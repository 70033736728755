import React from 'react'
import Layout from '../components/Layout'
import ContactPanel from '../components/ContactPanel'
import MvTextPage from '../components/common/MvTextPage'

export default () => (
  <Layout>
    <MvTextPage title="Kontakt">
      <div className="flex flex-wrap">
        <ContactPanel
          name="Carmen Grüblbauer"
          role="Geschäfts&shy;führung"
          emailName="geschaeftsfuehrung"
        />
        <ContactPanel
          name="Robin Unrath"
          role="1. Vorsitzender"
          emailName="vorstand"
        />
        <ContactPanel
          name="Katja Worms"
          role="Schrift&shy;führerin"
          emailName="schriftfuehrer"
        />
        <ContactPanel
          name="Sabrina Girschek"
          role="Jugend&shy;leitung"
          emailName="jugendleitung"
        />
        <ContactPanel
          name="Benjamin Rupp"
          role="Website"
          emailName="benjamin.rupp"
        />
      </div>
    </MvTextPage>
  </Layout>
)
