import React from 'react'
import ContactButton from './ContactButton'
import MvPanel from './common/MvPanel'

export default ({ role, name, emailName }) => (
  <MvPanel className="w-full md:w-1/2 lg:w-1/3">
    <span className="text-2xl font-bold break-words">{role}</span>
    <div>{name}</div>
    <ContactButton className="mt-8 mb-4 mx-auto" name={emailName} />
  </MvPanel>
)
